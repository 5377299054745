.tabs {
	width: 100%;
	margin: 0 auto 50px;
	text-align: center;
	// ↓ inline-blockで並べた要素の間にできる隙間対策
	font-size: 0;
}
/*タブのスタイル*/
.tab_menu {
	min-width: 100px;
	max-width: 150px;
	width: calc(100%/3);
	height: 30px;
	line-height: 30px;
	font-size: 12px;
	font-weight: 700;
	background-color: #f8f8f8;
	display: inline-block;
	transition: all .2s ease;
	border-radius: 5px 0 0 5px;
}
.tab_menu:first-of-type {
	border-radius: 5px 0 0 5px;
}
.tab_menu:last-of-type {
	border-radius: 0 5px 5px 0;
}
.tab_menu:hover {
	opacity: 0.75;
}
/*ラジオボタンを全て消す*/
input[name="tab_menu"] {
	display: none;
}
/*タブ切り替えの中身のスタイル*/
.tab_content {
	display: none;
}
/*選択されているタブを表示*/
#tab01:checked ~ #tabContent01,
#tab02:checked ~ #tabContent02,
#tab03:checked ~ #tabContent03 {
	display: block;
}
/*選択されているタブのスタイル*/
.tabs input:checked + .tab_menu {
	background-color: #007DFF;
	color: #fff;
}